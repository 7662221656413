<template>
  <div>
    <div class="cl">
      <div class="z">
        <base-button type="" size="mini" @click.native="$router.push({path:'/banner'})" rule="/banner">返回广告位</base-button>
        <add-button label="添加广告图片" type="primary" size="mini" @click.native="$router.push({path:'/banner/res/add',query:{id:id}})"></add-button>
      </div>
    </div>
    <div class="margin-top">
      <div class="pd-10 f14 bg-gray-3">广告位：<b>{{ cateDetail.name }}</b>，标识：<b>{{ cateDetail.akey }}</b></div>
      <el-table :data="list" default-expand-all style="width: 100%" :row-key="(row)=>{return row.is_spec && row.is_spec === 1 ? 'cell_'+row.id : 'sub_'+row.id}" :tree-props="{children: 'child'}" :indent="30">
        <el-table-column
          prop="title"
          label="标题">
        </el-table-column>
        <el-table-column
          prop="res_type"
          label="资源类型">
          <template slot-scope="scope">
            <span v-if="scope.row.res_type === 'image'">图片</span>
            <span v-if="scope.row.res_type === 'video'">视频</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="res_url"
          label="资源" width="100">
          <template slot-scope="scope">
            <template v-if="scope.row.res_type === 'image'">
            <el-image
              style="height: 60px;width:60px;"
              fit="cover"
              :src="scope.row.res_url.indexOf('http')>= 0 ? scope.row.res_url : $config.api.assetsUrl + scope.row.res_url"
              :preview-src-list="scope.row.res_url.indexOf('http')>= 0 ? [scope.row.res_url] : [$config.api.assetsUrl + scope.row.res_url]">
            </el-image>
            </template>
            <template v-if="scope.row.res_type === 'video'">
              <video width="100" height="60" controls class="vm">
                <source :src="scope.row.res_url.indexOf('http') >= 0 ? scope.row.res_url : $config.api.assetsUrl + scope.row.res_url" type="video/mp4">
              </video>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop="des"
          label="描述" width="300">
        </el-table-column>
        <el-table-column
          prop="out_url"
          label="链接">
          <template slot-scope="scope">
            <a :href="scope.row.out_url" target="_blank" v-if="scope.row.out_url">{{ scope.row.out_url }}</a>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="sort"
          label="排序" width="80">
        </el-table-column>
        <el-table-column
          prop="state"
          label="状态" style="font-size:12px;" width="80">
          <template slot-scope="scope">
            <el-tag :type="scope.row.state === 1 ? 'success' : 'danger'" disable-transitions>
              {{ scope.row.state === 0 ? '禁用' : '可用' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间" width="170"></el-table-column>
        <el-table-column align="right" width="180">
          <template slot-scope="scope">
            <edit-button @click.native="$router.push({path:'/banner/res/edit',query:{id:scope.row.id}})"
                         rule="/banner/res/edit" type="text"></edit-button>
            <del-button type="text" @click.native="del(scope.row.id)" rule="/banner/res/del"></del-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="padding-tb-20 text-right" v-if="list.length > 0">
        <el-pagination
          background
          layout="total,sizes,prev, pager, next,jumper"
          :page-sizes="[5,10, 50, 100, 200]"
          @size-change="changeSize"
          :page-size="searchForm.pageSize"
          :current-page.sync="searchForm.page"
          :total="totalCount"
          @current-change="pageChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GoodsSpecList",
  data() {
    return {
      list: [],
      totalPage: 0,
      totalCount: 0,
      id: '',
      cateDetail: {},

      searchForm:{
        page:1,
        pageSize:20,
        orderBy: 'sort',
        sortBy: 'asc'
      }
    }
  },
  created() {
    this.id = this.$route.query.id ? this.$route.query.id : 0;
  },
  methods: {
    changeSize(e){
      this.searchForm.pageSize = e;

      this.getList();
    },
    getList() {
      let _this = this;
      this.dataList = [];
      this.$http.request({
        url: '/banner/listPage',
        datas: {...this.searchForm, type_banner_id: this.id},
        success: (res) => {
          _this.list = res.list;
          _this.totalPage = res.totalPage;
          _this.totalCount = res.totalCount;
        }
      });
    },
    del(id) {
      let _this = this;
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.request({
          url: '/Banner/del',
          datas: {id: id},
          success: (res) => {
            _this.getList();
          }
        });
      }).catch(() => {
      });
    },
    pageChange(page) {
      this.page = page;
      this.getList();
    },
    getCateDetal() {
      let _this = this;
      _this.$http.request({
        url: '/SystemDict/detail',
        datas: {id: this.id},
        success: (res) => {
          _this.cateDetail = res;
        }
      })
    }
  },
  mounted() {
    if (this.id) {
      this.getList();
      this.getCateDetal();
    }
  }
}
</script>
